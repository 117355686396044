import * as React from 'react'

import s from './Questions.module.scss'
import { questionsBg } from '../../assets/images'
import { questions } from '../../data/questions'
import { QuestionItem } from '../generic/QuestionItem/QuestionItem'

export const Questions: React.FC = () => {
  return (
    <div className={s.questions}>
      <div className={s.questions__background} >
        <div className={s.questions__background__image_container}>
          <div className={s.questions__background__wrapper}>
            <img className={s.questions__background__image}  src={questionsBg} alt={'questionsBg'} />
          </div>
        </div>
      </div>
      <div className={s.questions__content}>
        <div className={s.header}>
          <div className={s.title}>
            <span>Вопросы</span>
            <br/>
            <span>и</span>
            <br/>
            <span className={s.title_sub}>Ответы</span>
          </div>
        </div>
        <div className={s.items}>
          {
            questions.map((q) => (
              <QuestionItem title={q.title} content={q.content} key={q.key}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}
