import * as React from 'react'

import s from './Footer.module.scss'

import {
  footerImage1,
  footerImage2,
  footerImage3,
  footerImage4,
  footerImage5,
  footerImage6,
} from '../../assets/images'
import classNames from 'classnames'
import { OFFER_AGREEMENT_URL, PRIVACY_POLICY_URL } from '../../const'

const IMAGES = [footerImage1, footerImage2, footerImage3, footerImage4, footerImage5, footerImage6]

export const Footer: React.FC = () => {

  const even = (n: number) => !(n % 2)

  return (
    <div className={s.footer}>
      <div className={s.footer__content}>
        <div className={s.images}>
          {
            IMAGES.map((image, i) => (
              <div
                className={classNames(
                  s.images__img,
                  even(i) ? s.images__img_top : s.images__img_bottom
                )}
                key={image}
              >
                <img
                  width='100%'
                  src={image}
                  alt={'footer-image'}
                />
              </div>
            ))
          }
        </div>
        <div className={s.data}>
          <div className={s.data__item}>
            <h4 className={s.data__title}>Местоположение</h4>
            <p>143080, Московская область, г.о. Одинцовский, г. Одинцово, дп Лесной городок,
              ул. Центральная дом 65/1, Офис 204</p>
          </div>
          <div className={s.data__item}>
            <h4 className={s.data__title}>Телефон</h4>
            <a className={s.data__text} href='tel:+78003337941'>+7 (800) 333-79-41</a>
          </div>
          <div className={s.data__item}>
            <h4 className={s.data__title}>Email</h4>
            <a className={s.data__text} data-email='1' href='mailto:support@tvoyrynok.online'>support@tvoyrynok.online</a>
            <a className={s.data__text} data-email='1' href='mailto:sales@tvoyrynok.online'>sales@tvoyrynok.online</a>
          </div>
          <div className={s.data__item}>
            <h4 className={s.data__title}>Реквизиты</h4>
            <p className={s.data__text}><span className={s.data__text__handing}>Название:</span> ООО «ТВОЙ РЫНОК ОНЛАЙН»</p>
            <p className={s.data__text}><span className={s.data__text__handing}>ИНН:</span> 5032327110</p>
            <p className={s.data__text}><span className={s.data__text__handing}>ОГРН:</span> 1215000032422</p>
            <p className={s.data__text}><span className={s.data__text__handing}>Расчётный счет:</span> 40702 810 4 1000 0800294</p>
            <p className={s.data__text}><span className={s.data__text__handing}>Корреспондентский счет:</span> 30101 810 1 4525 0000974</p>
            <p className={s.data__text}><span className={s.data__text__handing}>БИК банка:</span> 044525974</p>
            <p className={s.data__text}><span className={s.data__text__handing}>Банк:</span> АО «Тинькофф Банк»</p>
            <p className={s.data__text}><span className={s.data__text__handing}>ИНН банка:</span> 7710140679</p>
            <br/>
            <a className={s.data__text} target='_blank' href={OFFER_AGREEMENT_URL}>Пользовательское соглашение</a>
            <a className={s.data__text} target='_blank' href={PRIVACY_POLICY_URL}>Политика конфиденциальности</a>
          </div>
        </div>
        <div className={s.company_info}>
          Владелец платформы: ООО «ТВОЙ РЫНОК ОНЛАЙН» ИНН 5032327110 огрн 1215000032422
          Адрес: 143080, Московская область, г.о. Одинцовский, г. Одинцово, дп Лесной городок,
          ул. Центральная дом 65/1, Офис 204
        </div>
      </div>
    </div>
  )
}
