import { AboutItem, AboutItemType } from '../types'

import {
  customer1,
  customer2,
  customer3,
  customer4,
} from '../assets/images'

export const aboutItems: AboutItem[] = [
  {
    id: '1',
    title: 'Создание заказа',
    text: [
      'сервис доступен круглосуточно',
      'есть личный кабинет',
      'удобный каталог с «фотовитриной»',
      'маркировка продуктов халяль',
      'фиксированные цены',
    ],
    icon: customer4,
    type: AboutItemType.LEFT
  },
  {
    id: '2',
    title: 'Оплата заказа',
    text: [
      'online или курьеру',
      'наличный и безналичный расчет',
      'безопасность платежных систем',
      'чек со списком продуктов',
      'скидки и акции от продавцов',
    ],
    icon: customer1,
  },
  {
    id: '3',
    title: 'Сборка заказа',
    text: [
      'круглосуточное дежурство на рынке',
      'сборка заказа с разных прилавков ',
      'контроль взвешивания товаров',
      'проверка свежести продуктов',
      'упаковка для транспортировки',
      'если товар «так себе», мы позвоним вам',
    ],
    icon: customer2,
    type: AboutItemType.LEFT
  },
  {
    id: '4',
    title: 'Доставка заказа',
    text: [
      'ежедневно, в удобное для вас время',
      'самовывоз или доставка',
      'доставка в пределах города',
      'простой расчет курьерской стоимости',
      'бесконтактная передача',
    ],
    icon: customer3,
  },
]
