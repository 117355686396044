import { Advantage } from '../types'

export const advantages: Advantage[] = [
  {
    key: '1',
    text: 'Экономят время',
  },
  {
    key: '2',
    text: 'Едят свежие продукты',
  },
  {
    key: '3',
    text: 'Не толпятся в очередях',
  },
  {
    key: '4',
    text: 'Не таскают тяжелые сумки',
  }
]
