import * as React from 'react'

import s from './Divider.module.scss'

type Props = {
  image: string,
  ellipse: string,
};

export const Divider: React.FC<Props> = ({ image, ellipse  }) => {
  return (
    <div className={s.divider}>
      <img className={s.ellipse} src={ellipse} alt={'ellipse'} />
      <img className={s.divider__image} src={image} alt={'bg'} />
    </div>
  )
}
