import * as React from 'react'

import styles from './CookiesAlert.module.scss'

import { PRIVACY_POLICY_URL } from '../../const'
import { COOKIES, setData } from '../../utils/storageUtils'

type TProps = {
  onClose: () => void,
}

export class CookiesAlert extends React.PureComponent<TProps> {
  render(): JSX.Element {
    return (
      <div className={styles.cookies_alert}>
        <div className={styles.cookies_alert__title}>
          <div className={styles.cookies_alert__container}>
            <div className={styles.cookies_alert__container__message}>
              {this.gdprMessage()}
            </div>
            <div
              onClick={this.onConfirmGdpr}
              className={styles.cookies_alert__container__button}
            >
              <div className={styles.cookies_alert__container__button_text}>
                Ок
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  gdprMessage = () => {
    return (
      <div className={styles.cookies_alert__container__text}>
        Используя наш веб-сайт, вы соглашаетесь с использованием
        <a href={PRIVACY_POLICY_URL} className={styles.cookies_alert__container__link}>
          файлов cookie и нашей политикой конфиденциальности.
        </a>
      </div>
    )
  }

  onConfirmGdpr = () => {
    setData(COOKIES, String(true))

    this.props.onClose()
  }
}
