import { TMarketOrderStep } from '../types'

export const MARKET_ORDER_STEPS: TMarketOrderStep[] = [
  {
    id: '1',
    title: 'Сделайте заказ',
    text: [
      'выбираете как вам удобней покупать через список продуктов или у любимого продавца',
      'выбираете нужные продукты, добавляете в корзину, заказы сохраняются в личном кабинете',
      'удобный каталог с «фотовитриной»',
      'маркировка продуктов халяль',
      'фиксированные цены (такие же как и на рынке)',
    ],
  },
  {
    id: '2',
    title: 'Оплатите ваш заказ*',
    text: [
      'выберите удобный для вас способ — online или курьеру',
      'безналичный расчет – через приложение',
      'наличный расчет – на руки курьеру',
      'чек со списком продуктов приходит электронно на ваш email',
      'а еще скоро будут персональные скидки',
    ],
    comment: '*Платежи проходят через Тинькофф Банк так что ваши платежные данные в гарантированной безопасности',
  },
  {
    id: '3',
    title: 'Сборка заказа',
    text: [
      'доставка работает каждый день, а если надо сможем привезти заказ вечером после закрытия рынка',
      'мы соберем заказ от разных продавцов в один, проверим и упакуем',
      'взвесим',
      'проверим на свежесть',
      'упакуем для транспортировки',
      'если товар «так себе», мы позвоним вам и предупредим',
    ],
  },
  {
    id: '4',
    title: 'Доставка заказа',
    text: [
      'ежедневно, в удобное для вас время',
      'самовывоз или доставка',
      'доставка в пределах города',
      'простой расчет курьерской стоимости',
      'бесконтактная передача',
    ],
  },
  {
    id: '5',
    title: 'Сколько стоит услуга доставки?',
    text: [
      'Стоимость первой доставки — всего 49 рублей! Стоимость любой следующей доставки — 79 рублей',
    ],
  },
]
