import * as React from 'react'

import s from './About.module.scss'

import { TextItem } from '../generic/TextItem/TextItem'
import { aboutItems } from '../../data/aboutItems'

export const About = () => {
  return (
    <div className={s.about}>
      <div className={s.about__content}>
        <div className={s.items__container}>
          <div className={s.items}>
            {
              aboutItems.map((item) => (
                <TextItem title={item.title} icon={item.icon} type={item.type} key={item.id}>
                  <ul>
                    {item.text.map((text, i) => <li key={i}>{text}</li>)}
                  </ul>
                </TextItem>
              ))
            }
          </div>
      </div>
      </div>
    </div>
  )
}
