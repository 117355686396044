import * as React from 'react'

import s from './Header.module.scss'

import { logo, headerImage } from '../../assets/images'
import { StoreButtons } from '../StoreButtons/StoreButtons'
import { EButtonsLabel } from '../../types'

export const Header: React.FC = () => {
  return (
    <div className={s.header}>
      <div className={s.header__content}>
        <div className={s.top}>
          <div className={s.top__left}>
            <div className={s.top__left_content}>
              <span>Мы создали </span>
              <br/>
              <span className={s.top__left_url}>Твой Рынок</span>
              <br/>
              <div className={s.top__left_sub}>
                Чтобы вы покупали свежие и вкусные продукты
              </div>
            </div>
            <div className={s.buttons}>
              <StoreButtons label={EButtonsLabel.FIRST_PAGE} alignLeft />
            </div>
          </div>
          <div className={s.top__right}>
            <img
              src={logo}
              alt={'logo'}
              className={s.top__right_logo}
            />
          </div>
        </div>
        <div className={s.bottom}>
          <div className={s.bottom__left}>
            <div>
              С нашим сервисом купить натуральные продукты с рынка –
              все-равно что заказать пиццу – так же просто!
              Только еще полезно и выгодно!
            </div>
          </div>
          <div className={s.bottom__right}>
            <img
              src={headerImage}
              alt={'right-image'}
              className={s.bottom__right_image}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
