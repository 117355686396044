import React from 'react'

export type AboutItem = {
  id: string,
  title: string,
  text: string[],
  icon: string,
  type?: AboutItemType,
}

export enum AboutItemType {
  LEFT,
  RIGHT,
}

export type Question = {
  key: string,
  title: string,
  content: string,
}

export type Advantage = {
  key: string,
  text: string,
}

export type Store = {
  title: React.ReactNode,
  text: React.ReactNode,
}

export enum EButtonsLabel {
  FIRST_PAGE = '1',
  SECOND_PAGE = '2',
  THIRD_PAGE = '3',
  FOURTH_PAGE = '4',
  FIFTH_PAGE = '5',
  FOOTER = '6',
  HEADER = '7',
  MARKET = '8',
}

export type TMarkets = {
  srednoy: TMarket,
  central_podolsk: TMarket,
  central_yaroslavl: TMarket,
}

export type TMarket = {
  url: string,
  location: [number, number],
  pretext: string,
  name: string,
  nameInText: string,
  city: string,
  address: string,
  workTime: [string, string],
  deliveryTime: [string, string],
  endDeliveryTime: string,
  managers: {
    title: string,
    image: string,
  }[],
  screens?: string[],
}

export type TMarketOrderStep = {
  id: string,
  title: string,
  text: string[],
  comment?: string,
  icon?: string,
  type?: AboutItemType,
}
