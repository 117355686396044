import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { YMaps } from 'react-yandex-maps'

import styles from './App.module.scss'

import { MainPage } from '../MainPage/MainPage'
import { ChatPage } from '../ChatPage/ChatPage'
import {
  LOCATION_CHAT,
  LOCATION_MAIN,
  LOCATION_MARKET_CENTRAL_PODOLSK,
  LOCATION_MARKET_CENTRAL_YAROSLAVL,
  LOCATION_MARKET_SREDNOY,
} from '../../utils/locationUtils'
import { MarketPage } from '../MarketPage/MarketPage'
import { MARKETS } from '../../utils/marketUtils'

export const App: React.FC = () => {
  return (
    <YMaps>
      <div className={styles.app}>
        <Router>
          <Switch>
            <Route exact path={LOCATION_MAIN} component={MainPage}/>
            <Route exact path={LOCATION_CHAT} component={ChatPage}/>
            <Route path={LOCATION_MARKET_SREDNOY}>
              <MarketPage market={MARKETS.srednoy}/>
            </Route>
            <Route path={LOCATION_MARKET_CENTRAL_PODOLSK}>
              <MarketPage market={MARKETS.central_podolsk}/>
            </Route>
            <Route path={LOCATION_MARKET_CENTRAL_YAROSLAVL}>
              <MarketPage market={MARKETS.central_yaroslavl}/>
            </Route>
          </Switch>
        </Router>
      </div>
    </YMaps>
  )
}
