import React, { useState } from 'react'

import styles from './MainPage.module.scss'

import { dividerEllipse, dividerImage } from '../../assets/images'
import { Header } from '../Header/Header'
import { About } from '../About/About'
import { Customer } from '../Customer/Customer'
import { Divider } from '../Divider/Divider'
import { Questions } from '../Questions/Questions'
import { Footer } from '../Footer/Footer'
import { StoreButtons } from '../StoreButtons/StoreButtons'
import { HowItWorks } from '../HowItWorks/HowItWorks'
import { Advantages } from '../Advantages/Advantages'
import { CardInfo } from '../Cards/CardInfo/CardInfo'
import { CardHowItWorks } from '../Cards/CardHowItWorks/CardHowItWorks'
import { CookiesAlert } from '../CookiesAlert/CookiesAlert'
import { HeaderPanel } from '../HeaderPanel/HeaderPanel'
import { EButtonsLabel } from '../../types'
import { COOKIES, getData } from '../../utils/storageUtils'

export const MainPage: React.FC = () => {
  const [cookies, setCookies] = useState(getData(COOKIES) === 'true')
  const [headerPanel, setHeaderPanel] = useState(false)
  const appRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  const onScroll = () => {
    if (appRef && appRef.current) {
      const { scrollTop } = appRef.current

      if (scrollTop > 370 && !headerPanel) {
        setHeaderPanel(true)
      } else if (scrollTop <= 370 && headerPanel) {
        setHeaderPanel(false)
      }
    }
  }

  return (
    <div className={styles.main_page} onScroll={onScroll} ref={appRef}>
      <HeaderPanel show={headerPanel} />
      <Header />
      <HowItWorks />
      <StoreButtons label={EButtonsLabel.SECOND_PAGE} title={'Чтобы сходить на рынок теперь достаточно'}>
        <span className={styles.colored}>Скачать приложение</span>
      </StoreButtons>
      <About />
      <StoreButtons label={EButtonsLabel.THIRD_PAGE} title={'Твой Рынок'}>
        Всегда предлагает свежие продукты
        и быструю доставку!
        <br/>
        Оцените сами!
      </StoreButtons>
      <CardInfo />
      <StoreButtons
        label={EButtonsLabel.FOURTH_PAGE}
        title={
          <span>
            На
            <span className={styles.colored}> Твой Рынок </span>
            не надо ходить!
          </span>
        }
      >
        Скачайте приложение и заказывайте
        натуральные продукты
        онлайн!
      </StoreButtons>
      <Advantages />
      <StoreButtons
        label={EButtonsLabel.FIFTH_PAGE}
        title={
          <span>
            На
            <span className={styles.colored}> Твой Рынок </span>
            привезли все самое свежее!
          </span>
        }
      >
        Спеши попробовать!
      </StoreButtons>
      <CardHowItWorks />
      <Customer />
      <Divider image={dividerImage} ellipse={dividerEllipse}/>
      <Questions />
      <StoreButtons
        label={EButtonsLabel.FOOTER}
        title={
          <span>
            <span className={styles.colored}>Твой Рынок </span>
            уже работает!
          </span>
        }
      >
        Загляните и узнайте что вкусного
        ждет вас на прилавках!
      </StoreButtons>
      <Footer />
      {
        !cookies &&
        <CookiesAlert onClose={() => setCookies(true)}/>
      }
    </div>
  )
}
