import * as React from 'react'

import s from './Advantages.module.scss'

import { advantages } from '../../data/advantages'
import { star } from '../../assets/images'

export const Advantages = () => {

  return (
    <div className={s.advantages}>
      <div className={s.title}>Наши покупатели</div>
      <div className={s.cards}>
        {
          advantages.map((item) => <CardItem text={item.text} key={item.key}/>)
        }
        <div className={s.padding} />
      </div>
    </div>
  )
}

function CardItem(props: { text: string }){
  return (
    <div className={s.card}>
      <div className={s.card__icon}>
        <img className={s.card__star} src={star} alt={'star'}/>
      </div>
      <div className={s.card__title}>{props.text}</div>
    </div>
  )
}
