const APP_EVENTS = 'app'

export enum Analytics {
  TapAndroidApp = 'TapAndroidApp',
  TapIOSApp = 'TapIOSApp',
}

type AnalyticsGuestEvent =
  {
    name:
      typeof Analytics.TapAndroidApp |
      typeof Analytics.TapIOSApp,
    params: { label: string },
  }

type AnalyticsEvent = {
  GA: {
    category: string,
    action: string,
    label?: string,
    value?: number,
  }
}

function toAnalyticsEvent(e: AnalyticsGuestEvent): AnalyticsEvent {
  switch (e.name) {
    case Analytics.TapAndroidApp:
    case Analytics.TapIOSApp:
      return {
        GA: {
          category: APP_EVENTS,
          action: e.name,
          label: e.params.label,
        },
      }
  }
}

export function trackEvent(e: AnalyticsGuestEvent) {
  const event = toAnalyticsEvent(e)

  if (process.env.NODE_ENV === 'production') {
    sendEvent(event.GA.category, event.GA.action, event.GA.label, event.GA.value)
  }
}

function sendEvent(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
  gtag('event', eventAction, {
    event_category: eventCategory,
    ...(eventLabel && { event_label: eventLabel }),
    ...(eventValue && { value: eventValue }),
  })
}
