import * as React from 'react'

import s from './CardHowItWorks.module.scss'

import { Card } from '../Card/Card'
import { cardBg2  } from '../../../assets/images'

export const CardHowItWorks: React.FC = () => {
  return (
    <Card image={cardBg2}>
      <div className={s.title}>
        Каждое утро продавцы выкладывают на прилавки
        <span className={s.title_colored}> свежий товар и…</span>
      </div>
      <div className={s.content}>
        <p>
          ждут, пока мы закончим работу и успеем забежать на рынок,
          чтобы впопыхах схватить то, что осталось к вечеру.
        </p>
        <p>
          Теперь можно зайти на <span className={s.content_colored}>Твой Рынок</span> и собрать корзину любимых продуктов для вкусного
          и полезного ужина! Наши сотрудники выберут с прилавков все самое свежее и качественное,
          как для себя, и проследят за тем, чтобы товары не испортились во время доставки!
        </p>
      </div>
    </Card>
  )
}
