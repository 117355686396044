import * as React from 'react'

import s from './CardInfo.module.scss'

import { Card } from '../Card/Card'
import { cardInfoBg } from '../../../assets/images'

export const CardInfo: React.FC = () => {
  return (
    <Card image={cardInfoBg}>
      <div className={s.content_container}>
        <div className={s.title}>
          <span>Всегда покупаете еду </span>
          <span className={s.title_sub}>на рынке?</span>
        </div>
        <div className={s.content}>
          Значит вы прекрасно знакомы с ассортиментом и быстро соберете
          заказ! Мы привезем ваши любимые продукты,
          избавим от тяжелых сумок и сэкономим время.
        </div>
      </div>
      <div>
        <div className={s.title}>
          <span>Привыкли к </span>
          <span className={s.title_sub}>доставке</span>
          <span> еды из сетевых гипермаркетов?</span>
        </div>
        <div className={s.content}>
          Попробуйте заказать любимые позиции на сервисе Твой Рынок
          и почувствуйте разницу в качестве продуктов!
        </div>
      </div>
    </Card>
  )
}
