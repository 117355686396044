import * as React from 'react'

import s from './Customer.module.scss'
import {
  appStoreImage,
  customerPhones,
  googlePlayImage
} from '../../assets/images'
import { ANDROID_APP, IOS_APP } from '../../const'

export const Customer: React.FC = () => {
  return (
    <div className={s.customer}>
      <div className={s.customer__content}>
        <div>
          <div className={s.title}>
            <div><span className={s.title_sub}>Что получает</span> <br/>покупатель</div>
          </div>
          <div>
            <ul className={s.features_list}>
              <li>Выбор по реальным фото</li>
              <li>Из дома и офиса</li>
              <li>В любое время суток</li>
              <li>Список проверенных продавцов</li>
              <li>Удобная форма оплаты (нал/безнал)</li>
              <li>Товар от разных продавцов в одном заказе</li>
            </ul>
          </div>
        </div>
        <div className={s.right}>
          {/*<img className={s.right__image} src={customerPhones} alt={'customerPhones'} />*/}
          <div className={s.mobileButtons}>
            <div className={s.mobileButtons__title}>
              Попробуйте уже сегодня!
            </div>
            <div className={s.mobileButtons__buttons}>
              <div className={s.mobileButtons__buttons__content}>
                <a href={IOS_APP}>
                  <img className={s.mobileButtons__button} src={appStoreImage} alt={'ios'}/>
                </a>
                <a href={ANDROID_APP}>
                  <img className={s.mobileButtons__button} src={googlePlayImage} alt={'android'}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
