import * as React from 'react'
import classNames from 'classnames'

import s from './TextItem.module.scss'
import { AboutItemType } from '../../../types'

type Props = {
  title?: string,
  children?: React.ReactNode,
  icon?: string,
  type?: AboutItemType
};

export const TextItem: React.FC<Props> = ({title, children, icon, type = AboutItemType.RIGHT }) => {
  return (
    <div className={
      classNames(
        s.text_item,
        type === AboutItemType.LEFT && s.text_item__reverse
      )
    }>
      <div>
        <div className={s.title}>
          {title}
        </div>
        <div className={s.text}>
          {children}
        </div>
      </div>
      <div>
        <img className={s.icon} src={icon} alt={'img'}/>
      </div>
    </div>
  )
}
