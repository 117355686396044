import * as React from 'react'
import { Map, Placemark } from 'react-yandex-maps'
import classNames from 'classnames'

import styles from './MarketPage.module.scss'

import { EButtonsLabel, TMarket } from '../../types'
import { HeaderPanel } from '../HeaderPanel/HeaderPanel'
import { StoreButtons } from '../StoreButtons/StoreButtons'
import { MARKET_ORDER_STEPS } from '../../data/marketsInfo'
import { call, email } from '../../assets/images'
import { ANDROID_APP, IOS_APP } from '../../const'

type TProps = {
  market: TMarket,
}

export class MarketPage extends React.PureComponent<TProps> {
  render(): JSX.Element {
    return (
      <div className={styles.market_page}>
        <HeaderPanel show />
        {this.renderMarketInfo()}
        {this.renderMarketWork()}
        {this.renderDownloadApp()}
        {this.renderManagers()}
        {this.renderHowWork()}
        {this.renderHowOrder()}
        {this.renderFooter()}
      </div>
    )
  }

  renderMarketInfo() {
    const { market } = this.props
    const mapWidth = window.innerWidth > 840 ? 600 : window.innerWidth < 600 ? 0.9 * window.innerWidth : 480
    const mapHeight = mapWidth * 3 / 4

    return (
      <div className={styles.market_info}>
        <div className={styles.market_info__title}>
          <div className={styles.market_info__title_greeting}>
            Добро пожаловать {market.pretext}
          </div>
          <div className={styles.market_info__title_name}>
            {market.name}
          </div>
        </div>
        <div className={styles.market_info__location}>
          <div className={styles.market_info__location_address}>
            <div className={styles.market_info__location_address__label}>Адрес</div>
            <div>{market.city}, {market.address}</div>
          </div>
          <div className={styles.market_info__location_map} style={{ width: `${mapWidth}px`, height: `${mapHeight}px` }}>
            <Map defaultState={{ center: market.location, zoom: 16 }} width={mapWidth} height={mapHeight}>
              <Placemark geometry={market.location} />
            </Map>
          </div>
        </div>
      </div>
    )
  }

  renderMarketWork() {
    const { market } = this.props

    return (
      <div className={styles.market_work}>
        <div className={styles.market_work__content}>
          <div className={styles.market_work__time}>
            График работы рынка:
            <div>
              Пн-Вс с {market.workTime[0]} до {market.workTime[1]}
            </div>
          </div>
          <div className={styles.market_work__delivery_time}>
            График работы службы доставки:
            <div>
              Пн-Вс с {market.deliveryTime[0]} до {market.deliveryTime[1]}*
            </div>
          </div>
        </div>
        <div className={styles.market_work__delivery_time__comment}>
          *Прием заказов через приложение осуществляется ежедневно до {market.endDeliveryTime}
        </div>
      </div>
    )
  }

  renderDownloadApp() {
    const { market } = this.props

    return (
      <div className={styles.market_app}>
        <div className={styles.market_app__title}>
          Скачайте приложение чтобы покупать онлайн на прилавках {market.nameInText}
        </div>
        <StoreButtons label={EButtonsLabel.MARKET}/>
      </div>
    )
  }

  renderManagers() {
    const { market } = this.props

    return (
      <div className={styles.market_managers}>
        <div className={styles.market_managers__title}>
          Дежурные менеджеры
        </div>
        <div className={styles.market_managers__list}>
          {market.managers.map((manager) => {
            return (
              <div className={styles.market_managers__item}>
                <div className={styles.market_managers__item_img}>
                  <img className={styles.market_managers__item_img} src={manager.image}/>
                </div>
                <div className={styles.market_managers__item_title}>
                  {manager.title}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderHowWork() {
    const { market } = this.props

    return (
      <div className={styles.market_how_work}>
        <div className={styles.market_how_work__title}>
          <div className={styles.market_how_work__title_text}>Как работает</div>
          <div className={styles.market_how_work__title_name}>служба доставки {market.nameInText}</div>
        </div>
        <div className={styles.market_how_work__desc}>
          <p className={styles.market_how_work__desc_title}>
            <div className={styles.market_how_work__desc_num}>1</div> Скачайте приложение на ваше мобильное устройство На iOS
            <a className={styles.market_how_work__desc_link} href={IOS_APP}>здесь</a>
            , а на Андроид <a className={styles.market_how_work__desc_link} href={ANDROID_APP}>здесь</a>.
          </p>
          <p className={classNames([styles.market_how_work__desc_title, styles.market_how_work__padding])}>
            <div className={styles.market_how_work__desc_num}>2</div> Выберите ваш город – «{market.city}»
          </p>
          <p className={styles.market_how_work__desc_title}>
            <div className={styles.market_how_work__desc_num}>3</div>
            Выберите рынок – «{market.name}»
          </p>
          <p className={classNames([styles.market_how_work__desc_title, styles.market_how_work__padding])}>
            <div className={styles.market_how_work__desc_num}>4</div>
            Получите доступ ко всем продавцам и всем товарам представленным на рынке.
          </p>
          <p className={styles.market_how_work__desc_title}>
            <div className={styles.market_how_work__desc_num}>5</div>
            Выбираете что и у кого купить. Покупайте у тех, кому доверяете!
          </p>
          <div className={classNames([styles.market_how_work__desc_title, styles.market_how_work__padding])}>
            <div>
              <p>
                <div className={styles.market_how_work__desc_num}>6</div>
                После того как вы собрали заказ в корзину завершите оформление:
              </p>
              <ul className={styles.market_how_work__desc_list}>
                <li>укажите адрес</li>
                <li>заполните данные</li>
                <li>выберите удобное время для доставки</li>
                <li>оплатите заказ</li>
              </ul>
            </div>
          </div>
          <div>
            <p className={styles.market_how_work__desc_title}>
              <div className={styles.market_how_work__desc_num}>7</div>
              После завершения оформления заказа его увидит наш сотрудник доставки:
            </p>
            <ul className={styles.market_how_work__desc_list}>
              <li>соберет его у продавцов</li>
              <li>проверит качество продуктов и вес</li>
              <li>упакует в соответствии с товарным соседством</li>
              <li>бережно доставит его по вашему адресу в назначенный час</li>
              <li>если товар «так себе», мы позвоним и предупредим</li>
            </ul>
          </div>
        </div>
        {
          market.screens &&
          <div className={styles.market_how_work__screens}>
            {market.screens.map((screen) => {
              return (
                <div className={styles.market_how_work__screens_item}>
                  <img className={styles.market_how_work__screens_item_img} src={screen}/>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }

  renderHowOrder() {
    return (
      <div className={styles.market_how_order}>
        <div className={styles.market_how_order__title}>
          <div className={styles.market_how_order__title_text}>Как сделать заказ</div>
          <div className={styles.market_how_order__title_name}>в приложении доставки?</div>
        </div>
        {
          MARKET_ORDER_STEPS.map((item) => {
            return (
              <div className={styles.market_how_order__step}>
                <p className={styles.market_how_order__step__title}>{item.title}</p>
                <ul className={styles.market_how_order__step__desc}>
                  {item.text.map((text) => <li>{text}</li>)}
                </ul>
                {item.comment && <p className={styles.market_how_order__step__comment}>{item.comment}</p>}
              </div>
            )
          })
        }
        <div className={styles.market_how_order__delivery_info}>
          <p className={styles.market_how_order__delivery_info__title}>Что такое нестандартная доставка?</p>
          <p>Курьерская стоимость зависит от веса заказа и удаленности адреса доставки от рынка. Приложение рассчитает эту сумму автоматически.</p>
          <p>На случай, если вам интересно, мы описали принцип.</p>
          <p>Стоимость первой доставки — всего 49 рублей! Стоимость любой следующей доставки — 79 рублей</p>
        </div>
      </div>
    )
  }

  renderFooter() {
    return (
      <div className={styles.market_footer}>
        <div className={styles.market_footer__content}>
          <div className={styles.market_footer__title}>
            <div>Остались вопросы?</div>
            <div>Позвоните нам — это бесплатно!</div>
          </div>
          <p className={styles.market_footer__contact_label}>Контакты службы поддержки:</p>
          <div className={styles.market_footer__contact}>
            <div className={styles.market_footer__phone}>
              <div className={styles.market_footer__phone_icon}>
                <img src={call} className={styles.market_footer__phone_icon}/>
              </div>
              <a href='tel:+78003337941'>+7 (800) 333-79-41</a>
            </div>
            <div className={styles.market_footer__email}>
              <div className={styles.market_footer__email_icon}>
                <img src={email} className={styles.market_footer__email_icon}/>
              </div>
              <a href='mailto:support@tvoyrynok.online'>support@tvoyrynok.online</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
