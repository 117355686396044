import * as React from 'react'

import s from './HeaderPanel.module.scss'

import { StoreButtons } from '../StoreButtons/StoreButtons'
import { EButtonsLabel } from '../../types'

type TProps = {
  show: boolean,
}

export const HeaderPanel: React.FC<TProps> = ({ show }) => {
  if (!show) {
    return <div/>
  }

  return (
    <div className={s.header_panel}>
      <div className={s.header_panel__content}>
        <div className={s.header_panel__content__buttons}>
          <StoreButtons label={EButtonsLabel.HEADER} alignLeft />
        </div>
        <div className={s.header_panel__content__head}>
          <div className={s.header_panel__content__head_text}>Твой Рынок</div>
        </div>
      </div>
    </div>
  )
}
