import * as React from 'react'

import s from './HowItWorks.module.scss'

export const HowItWorks = () => {
  return (
    <div className={s.about}>
      <div className={s.about__content}>
        <div className={s.title}>
          <span>Как работает</span>
          <br/>
          <span className={s.title__url}>Твой Рынок?</span>
        </div>
        <div className={s.text}>
          <span>Вы выбираете рынок или ярмарку, получаете доступ ко всем продавцам и всем товарам на этом рынке.</span><br/>
          <span>Вы сами выбираете что и у кого купить. Покупайте у тех, кому доверяете!</span><br/>
          <span>Мы собираем заказ, проверяем его качество и вес, упаковываем и бережно доставляем его по Вашему адресу в назначенный час.</span>
        </div>
      </div>
    </div>
  )
}
