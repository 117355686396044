import * as React from 'react'

import s from './Card.module.scss'

type props = {
  image?: string,
  children?: React.ReactNode
}

export const Card: React.FC<props> = ({ children, image }) => {
  return (
    <div
      className={s.card_container}
      style={{ backgroundImage: `url('${image}')` }}
    >
      <div className={s.card}>
        {children}
      </div>
    </div>
  )
}
