import { TMarkets } from '../types'
import {
  LOCATION_MARKET_CENTRAL_PODOLSK,
  LOCATION_MARKET_CENTRAL_YAROSLAVL,
  LOCATION_MARKET_SREDNOY,
} from './locationUtils'
import {
  srednoyPuzankov,
  srednoyGrishin,
  podolskZudin,
  podolskMinskiy,
  yaroslavlGusev,
  yaroslavlBazarov,
  podolskSellers,
  podolskProducts,
  yaroslavlSellers,
  yaroslavlProducts,
} from '../assets/images'

export const MARKETS: TMarkets = {
  srednoy: {
    url: LOCATION_MARKET_SREDNOY,
    location: [56.3087018, 43.9889928],
    pretext: 'в',
    name: 'Торговые ряды «Средной»',
    nameInText: 'Торговых рядов «Средной»',
    city: 'Нижний Новгород',
    address: 'Белинского 26',
    workTime: ['09:00', '18:00'],
    deliveryTime: ['9:30', '20:00'],
    endDeliveryTime: '17:00',
    managers: [
      {
        title: 'Гришин Роман Сергеевич',
        image: srednoyGrishin,
      },
      {
        title: 'Пузанков Максим Александрович',
        image: srednoyPuzankov,
      }
    ]
  },
  central_podolsk: {
    url: LOCATION_MARKET_CENTRAL_PODOLSK,
    location: [55.429182, 37.549689],
    pretext: 'на',
    name: 'Центральный рынок Подольска',
    nameInText: 'Центрального рынка Подольска',
    city: 'Подольск',
    address: 'Комсомольская 3',
    workTime: ['09:00', '17:30'],
    deliveryTime: ['09:30', '18:00'],
    endDeliveryTime: '16:30',
    managers: [
      {
        title: 'Зудин Александр Алексеевич',
        image: podolskZudin,
      },
      {
        title: 'Минский Станислав Алексеевич',
        image: podolskMinskiy,
      }
    ],
    screens: [podolskProducts, podolskSellers],
  },
  central_yaroslavl: {
    url: LOCATION_MARKET_CENTRAL_YAROSLAVL,
    location: [57.62471, 39.88959],
    pretext: 'на',
    name: 'Центральный рынок Ярославля',
    nameInText: 'Центрального рынка Ярославля',
    city: 'Ярославль',
    address: 'Депутатская улица 7',
    workTime: ['09:00', '17:00'],
    deliveryTime: ['09:30', '18:00'],
    endDeliveryTime: '16:00',
    managers: [
      {
        title: 'Базаров Евгений Александрович',
        image: yaroslavlBazarov,
      },
      {
        title: 'Гусев Максим Сергеевич',
        image: yaroslavlGusev,
      }
    ],
    screens: [yaroslavlProducts, yaroslavlSellers],
  }
}
