import React, { useState } from 'react'

import s from './QuestionItem.module.scss'
import { ArrowIcon } from '../../icons/icons'
import classNames from 'classnames'

type Props = {
  title: string,
  content: string,
}

export const QuestionItem: React.FC<Props> = ({ title, content}) => {
  const [toggle, setToggle ] = useState<boolean>(false)

  const onSetToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setToggle((t) => !t)
  }

  return (
    <div className={s.question_item}>
      <div className={s.title} onClick={onSetToggle}>
        <div>{title}</div>
        <div
          className={classNames(
            toggle && s.title__arrow_icon__open
          )}
        >
          <ArrowIcon />
        </div>
      </div>
      {toggle && <div className={s.content}>{content}</div>}
    </div>
  )
}
