import * as React from 'react'
import classNames from 'classnames'

import s from './StoreButtons.module.scss'

import { ANDROID_APP, IOS_APP } from '../../const'
import { appStoreImage, googlePlayImage } from '../../assets/images'
import { Analytics, trackEvent } from '../../utils/analyticsUtils'

type TProps = {
  label: string,
  title?: React.ReactNode,
  children?: React.ReactNode,
  alignLeft?: boolean,
}

export const StoreButtons: React.FC<TProps> = ({ title, label, children, alignLeft }) => {
  return (
    <div className={classNames(
      s.store_buttons,
      alignLeft && s.store_buttons__left_align
    )}>
      <div className={s.store_buttons__container}>
        <div className={s.title}>{title}</div>
        {children && <div className={s.content}>{children}</div>}
        <div className={s.buttons}>
          <a
            className={s.button}
            href={IOS_APP}
            onClick={() => trackEvent({ name: Analytics.TapIOSApp, params: { label } })}
          >
            <img className={s.button_image} src={appStoreImage} alt={'ios'}/>
          </a>
          <a
            href={ANDROID_APP}
            onClick={() => trackEvent({ name: Analytics.TapAndroidApp, params: { label } })}
          >
            <img className={s.button_image} src={googlePlayImage} alt={'android'}/>
          </a>
        </div>
      </div>
    </div>
  )
}
